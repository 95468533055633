<template lang="pug">
  .film.text-white
    .fixed.overlay.bg-black.flex.lg_py-16vh.lg_px-12vw
      .relative.w-full(v-if="doc")
        vimeo-player(ref="vimeo", vimeoURL="https://vimeo.com/510851684", bgSize="contain", @playing="$event => { playing = $event }", @mutechange="$event => { muted = $event }", :clickable="false", @loaded="loaded = true", :loop="false", :autoplay="autoplay")

        button.absolute.overlay.flex.items-center.justify-center(@click="onPlayBtn", :class="{'opacity-25': !loaded}", :disabled="!loaded", :aria-label="!loaded ? 'Loading...' : playing ? 'Pause' : 'Play'")
          <svg v-show="!playWasInitiated || playing !== true" width="38" height="44" viewBox="0 0 38 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M38 22L0.499998 43.6506L0.5 0.349364L38 22Z" fill="white"/>
          </svg>
    //- footer-links.absolute.bottom-0.left-0.w-full.text-center
      button.inline-block(@click="onPlayBtn") {{ !playing ? 'Play' : 'Pause' }}
    footer-links.fixed.bottom-0.right-0.z-30.px-3.flex.items-center.pointer-events-none
      //- shadow Menu for alignment
      button.p-3.-mb-3.focus_outline-none.invisible Menu
      button.p-3.-mb-3.text-white.pointer-events-auto(:disabled="playing === null", :class="{'opacity-25': playing === null}", @click="onMuteBtn")
        svg-audio(:muted="muted")
</template>

<script>
import { mapState } from 'vuex'
import store from '@/store'
export default {
  name: 'Film',
  data () {
    return {
      loaded: false,
      playWasInitiated: false,
      playing: null,
      muted: true,
      autoplay: false
    }
  },
  computed: {
    ...mapState(['winW']),
    doc () {
      return this.$store.state.docs.find(doc => doc.type === 'film')
    }
  },
  methods: {
    async onPlayBtn () {
      await this.$refs.vimeo.playBtnClick(!this.playWasInitiated)
      this.playWasInitiated = true
    },
    onMuteBtn () {
      this.$refs.vimeo.toggleMute()
    }
  },
  beforeRouteEnter (to, from, next) {
    store.commit('setUIColor', 'white')
    next()
  }
}
</script>

<style>
</style>
